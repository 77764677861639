
$(document).foundation();

$(document).ready(function () {

    // Search
    $('.header ul.menu').append('<li><i class=\"fa fa-search\"></i></li>');
    $('.header .fa-search').click(function () {
        $('.header .search').slideToggle();
    });
    $(window).resize(function () {
        $('.header .search').slideUp();
    });

    // Heroslider
    if ($('.heroslider').length) {
        $('.heroslider').slick({
            dots: true,
            arrows: false,
            infinite: true,
            fade: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });
    }

    // Portraitslider
    if ($('.portraitslider').length) {
        $('.portraitslider').slick({
            dots: false,
            arrows: true,
            infinite: true,
            fade: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
    }

    // Overlay (Mobile Menu)
    $('.header .mobile i.fa-bars').click(function () {
        $('.overlay').fadeToggle();
    });
    $('.overlay .close').click(function () {
        $('.overlay').fadeOut();
    });
    $(window).on('resize', function () {
        $('.overlay').fadeOut();
    });

});